<script lang="ts" setup>
defineProps({
  endDate: {
    type: String,
    required: true,
  },
  individual: {
    type: Boolean,
    default: false,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
})

// NB: Emitting 'cancel' symbolises intent to cancel trial,
// not to cancel the dialog
const emit = defineEmits(['cancel'])

const isShown = defineModel<boolean>()
</script>

<template>
  <v-dialog v-model="isShown" max-width="632">
    <v-card data-cy="cancel_subscription_dialog">
      <v-card-title class="text-h5">
        {{ $t('dialog.business.cancel_subscription.title') }}
      </v-card-title>
      <v-card-text v-if="individual">
        <div class="pb-4" data-cy="cancel_dialog_individual">
          <p class="mb-3">
            <i18n-t keypath="dialog.business.cancel_subscription.text_individual">
              <template #endDate>
                <span class="font-weight-bold">{{ endDate }}</span>
              </template>
            </i18n-t>
          </p>
          <ul class="pl-6">
            <li>{{ $t('dialog.business.downgrade.item_individual_1') }}</li>
            <li>{{ $t('dialog.business.downgrade.item_individual_2') }}</li>
            <li>{{ $t('dialog.business.downgrade.item_individual_3') }}</li>
          </ul>
        </div>
      </v-card-text>
      <v-card-text v-else>
        <div class="pb-4">
          <p class="mb-3">
            <i18n-t keypath="dialog.business.cancel_subscription.text">
              <template #endDate>
                <span class="font-weight-bold">{{ endDate }}</span>
              </template>
            </i18n-t>
          </p>
          <ul class="pl-6">
            <li>{{ $t('dialog.business.downgrade.item_1') }}</li>
            <li>{{ $t('dialog.business.downgrade.item_2') }}</li>
            <li>{{ $t('dialog.business.downgrade.item_3') }}</li>
          </ul>
        </div>
      </v-card-text>
      <v-divider />
      <div class="d-flex justify-space-between px-8 py-4">
        <v-btn size="x-large" color="info" variant="outlined" @click="isShown = false">
          {{ $t('global.no_thanks') }}
        </v-btn>
        <v-btn
          data-userpilot-id="cancel_subscription_btn"
          size="x-large"
          color="error"
          data-cy="confirmSubscriptionCancelBtn"
          variant="elevated"
          :disabled="isLoading"
          :loading="isLoading"
          @click="emit('cancel')"
        >
          {{ $t('business.billing.plan.cancel_subscription') }}
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>
